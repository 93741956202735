<template>
	<div>
		<div class="person_infor">
			<div class="login_top">
				<div class="img"> <img src="@/assets/login/flag.png"></div>
				<div class="login_title">{{$t('title.myRelease')}}</div>
			</div>
			<div class="filter">
				<div class="time">
					<span class="label">{{$t('submitTime')}}:</span>
					<el-date-picker value-format="yyyy-MM-dd" v-model="timeValue" type="daterange"
						:range-separator="$t('to')" :start-placeholder="$t('startTime')"
						:end-placeholder="$t('endTime')" @change="dateChange">
					</el-date-picker>
				</div>
				<div class="status">
					<span class="label">{{$t('state')}}:</span>
					<el-select v-model="check" :placeholder="$t('release.pleaseSelect')" clearable
						@change="selectChange">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="btn">
					<div class="search" @click="searchClick">{{$t('search')}}</div>
					<div class="reset" @click="resetClick">{{$t('reset')}}</div>
				</div>
			</div>
			<div class="table">
				<el-table :data="messageList" style="width: 100%" :header-cell-style="{background:'#F6F7F9',}">
					<el-table-column type="index" label="" align="center">
					</el-table-column>
					<el-table-column prop="date" :label="$t('releaseContent')" width="180" align="center">
						<template slot-scope="scope">
							<div class="infor">
								<div class="infor_img">
									<img :src="scope.row.thumb[0]">
								</div>
								<div class="infor_title">
									{{scope.row.title}}
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="create_time" :label="$t('submitTime2')" align="center">
					</el-table-column>
					<el-table-column :label="$t('state')" align="center">
						<template slot-scope="scope">
							<div class="status">
								<span class="blue" v-if="scope.row.check==1">{{$t('my.checkIn')}}</span>
								<span class="gray" v-if="scope.row.check==2">{{$t('my.applySuccess')}}</span>
								<span class="red" v-if="scope.row.check==3">{{$t('my.applyFailure')}}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="remark" :label="$t('reason')" align="center">
					</el-table-column>
					<el-table-column :label="$t('isStop')" align="center">
						<template slot-scope="scope">
							<el-switch v-if="scope.row.check==2" @change="switchChange($event,scope.row.id)"
								active-color="#ff0000" inactive-color="#eeeeee" v-model="scope.row.status">
							</el-switch>
						</template>
					</el-table-column>
					<el-table-column :label="$t('operation')" align="center">
						<template slot-scope="scope">
							<div class="operate">
								<span class="blue"
									@click="toApplyReleaseDetail(scope.row.id,scope.row.check)">{{$t('checkDetail')}}</span>
								<el-popconfirm :title="$t('my.deleteAllContent')"
									@confirm="deleteRelease(scope.row.id)">
									<span class="gray" slot="reference">{{$t('delete')}}</span>
								</el-popconfirm>
								<span class="gray" @click="resubmitRelease(scope.row.id)"
									v-if="scope.row.check!=1">{{$t('submitAgain')}}</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="pagination">
				<el-pagination background layout="prev, pager, next,sizes" :total="total"
					@current-change="currentChangeClick" @size-change="sizeChangeClick" :current-page="currentPage"
					:page-size="pageSize" :background="true" popper-class="select_bottom" :page-sizes="pageSizes">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				options: [{
					value: 0,
					label: this.$t('other.all')
				}, {
					value: 1,
					label: this.$t('other.applyIn')
				}, {
					value: 3,
					label: this.$t('other.applyFailure')
				}, {
					value: 2,
					label: this.$t('other.applySuccess')
				}],

				timeValue: '',
				startTime: '', //开始日期
				endTime: '', //结束日期
				check: 0, //选择的类型
				messageList: [], //信息列表
				total: 8, //总条数
				currentPage: 1, //当前页数
				pageSize: 8, //每页的条数
				pageSizes: [8, 10, 20, 30, 40, 50, 100],
			}
		},
		mounted() {
			this.msgList();
		},
		methods: {
			// 是否停用
			switchChange(val, id) {
				this.$http.isStopMessage({
					id,
				}).then(res => {
					if (res.code == 1) {
						// this.msgList();
					} else {
						this.$message.info(res.msg);
					}
				})
			},
			// 获取分类信息
			msgList() {
				let data = {
					startTime: this.startTime,
					endTime: this.endTime,
					pageNo: this.currentPage,
					pageSize: this.pageSize,
					check: this.check,
				}
				this.$http.msgList(data).then(res => {
					if (res.code == 1) {
						this.messageList = res.data.data;
						this.messageList = this.messageList.map(item => {
							return {
								...item,
								status: item.status == 1 ? false : true,
							}
						})
					} else {
						this.$message.info(res.msg);
					}
				})
			},
			// 下拉框筛选
			selectChange(e) {
				this.check = e;
			},
			// 时间选择器事件
			dateChange(e) {
				console.log(e);
				this.startTime = e[0];
				this.endTime = e[1];
			},
			currentChangeClick(currentPage) {
				this.currentPage = currentPage;
				this.msgList();

			},
			sizeChangeClick(pageSize) {
				this.pageSize = pageSize;
				this.msgList();
			},
			//搜索
			searchClick() {
				this.msgList();
			},
			//重置
			resetClick() {
				this.timeValue = '';
				this.check = 0;
				this.startTime = '';
				this.endTime = '';
				this.msgList();
			},
			// 查看详情
			toApplyReleaseDetail(id, check) {
				this.$router.push({
					path: '/applyReleaseDetail',
					query: {
						information_id: id,
						check: check,
					}
				})
			},
			// 删除
			deleteRelease(information_id) {
				this.$http.deleteInformation({
					information_id: information_id,
				}).then(res => {
					if (res.code == 1) {
						this.$message.success(res.msg);
						this.msgList();
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 重新提交
			resubmitRelease(information_id) {
				console.log("pp");
				this.$router.push({
					path: '/resubmitRelease',
					query: {
						information_id: information_id,
					}
				})
			},


		},

	}
</script>

<style scoped="" lang="less">
	.person_infor {
		width: 926px;
	}

	.login_top {
		display: flex;
		padding: 24px;
		border-bottom: 1px solid #F3F5F6;

		.img {
			display: flex;
			align-items: center;

			img {
				width: 18px;
				height: 18px;
			}
		}

		.login_title {
			margin-left: 8px;
			font-weight: bold;
			font-size: 18px;
		}
	}

	.filter {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin: 16px 0 32px;
		padding-left: 24px;

		.label {
			margin-right: 12px;
		}

		.time {
			margin-right: 32px;
			padding-bottom: 16px;

			/deep/ .el-date-editor {
				width: 380px;
			}

			/deep/ .el-date-editor .el-range-separator {
				width: 8% !important;
			}
		}

		.status {
			margin-right: 32px;
			padding-bottom: 16px;

			/deep/ .el-input__inner {
				width: 150px;
			}
		}

		.btn {
			display: flex;
			padding-bottom: 16px;

			&:hover {
				cursor: pointer;
			}

			.search {
				padding: 10px 24px;
				text-align: center;
				background: #E60012;
				opacity: 1;
				border-radius: 20px;
				font-size: 14px;
				color: #FFFFFF;
				margin-right: 12px;

			}

			.reset {
				font-size: 14px;
				padding: 10px 24px;
				text-align: center;
				background: #F6F7F9;
				opacity: 1;
				border-radius: 20px;
			}
		}
	}



	.table {
		padding: 0 24px;

		.infor {
			display: flex;
			// justify-content: center;
			align-items: center;

			.infor_img {
				img {
					width: 48px;
					height: 48px;
				}
			}

			.infor_title {
				margin-left: 8px;
				width: 132;
				height: 45px;
				color: #333333;
				font-weight: 500;
				text-overflow: -o-ellipsis-lastline;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				line-clamp: 2;
				-webkit-box-orient: vertical;
				word-break: break-all;
				word-wrap: break-word;
			}
		}

		.status {
			font-size: 12px;

			&>span:hover {
				cursor: pointer;
			}

			.red {
				color: #E60012;
			}

			.blue {
				color: #4177DD;
			}

			.gray {
				color: #999999;
			}

		}

		.operate {
			span {
				font-size: 12px;
				display: inline-block;

				&:hover {
					cursor: pointer;
				}
			}

			.blue {
				color: #4177DD;
			}

			.gray {
				margin-left: 8px;
				color: #999999;
			}
		}
	}

	.pagination {
		margin: 32px 0 32px;
		display: flex;
		justify-content: flex-end;
	}

	/deep/ .el-table .cell {
		text-overflow: clip !important;
		word-break: normal;
	}
</style>
